import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import YouTube from 'react-youtube-embed';

import './header.scss';
import mainLogo from '../../images/NCD-logo-main.png';
export const Header = (props) => {
  const data = useStaticQuery(graphql`
    query headerQuery {
      header: contentfulHeader {
        logo {
          description
          file {
            url
          }
        }
        mobileNavLogo {
          description
          file {
            url
          }
        }
        backgroundImage {
          description
          file {
            url
          }
        }
        showBackgroundImageOn {
          pageUrl
        }
        headerPromoLine
        youtubeVideoId
        menuItems {
          ... on ContentfulPage {
            name
            pageUrl
            pageType
            internal {
              type
            }
          }
          #... on ContentfulContactUs {
          #  name
          #  pageUrl
          #  internal {
          #    type
          #  }
          #}
        }
        #socialTag
      }
    }
  `);

  const [isNavToggled, setIsNavToggled] = React.useState(false);

  const handleNavBtnClick = (evt) => {
    evt.preventDefault();

    setIsNavToggled(!isNavToggled);
  };

  const hasBackground = 'home' === props.content.pageUrl;
  let inlineStyle = {};
  {
    if (hasBackground) {
      inlineStyle = {
        backgroundImage: `url(${data.header.backgroundImage.file.url})`,
      };
    }
  }

  return (
    <header
      className={`page-header ${isNavToggled ? 'is-nav-toggled' : ''} ${
        hasBackground ? 'page-header__background' : ''
      }`}
      style={inlineStyle}
    >
      <div className="contain page-header__contain">
        {data.header.socialTag && (
          <div className="page-header__social">{data.header.socialTag}</div>
        )}
        {data.header.menuItems && (
          <React.Fragment>
            <div className="page-header__navbtn">
              <button
                aria-controls="menu"
                aria-expanded={isNavToggled}
                aria-label={isNavToggled ? `close menu` : `open menu`}
                className="page-header__navbtn__btn"
                onClick={handleNavBtnClick}
                type="button"
              >
                {isNavToggled ? `Close` : `Menu`}
              </button>
            </div>
            <nav
              aria-label="primary navigation"
              className="page-header__nav"
              id="menu"
            >
              <ul>
                {data.header.menuItems.map((item, itemIndex) => {
                  const isCurrent = item.pageUrl === props.content.pageUrl;
                  const text = item.menuText || item.name;
                  let url = `/${item.pageUrl}/`;

                  if (item.pageType === 'homepage') {
                    url = '/';
                  }

                  if (item.internal.type === 'ContentfulContactUs') {
                    url = `mailto:${item.pageUrl}`;
                  }

                  return (
                    <li key={itemIndex}>
                      <a
                        className={`${isCurrent ? 'is-current' : ''}`}
                        href={url}
                      >
                        {text}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </React.Fragment>
        )}
        <div className="page-header__logo">
          <a
            aria-label="return to homepage"
            className="page-header__logo__link"
            href="/"
          />
          {/*{data.header.logo && (
            <img
              alt={data.header.logo.description}
              src={`${data.header.logo.file.url}?w=360`}
            />
          )*/}
          <img
            alt={data.header.logo.description}
            src={`${mainLogo}?w=360`}
            style={{ maxWidth: '360px' }}
          />
          {/*{data.header.menuItems && data.header.mobileNavLogo && (
            <div
              className="page-header__logo__nav"
              style={{
                backgroundImage: `url(${data.header.mobileNavLogo.file.url}?w=160)`,
              }}
            />
          )}*/}
        </div>
      </div>
      {hasBackground && (
        <div className="page-header__promo">Tickets from £4</div>
      )}
      {hasBackground && data.header.youtubeVideoId && (
        <div className="page-header__youtube">
          <div className="page-header__youtube__iframe">
            <YouTube id={data.header.youtubeVideoId} />
          </div>
        </div>
      )}
    </header>
  );
};
